<template>
    <div class="guize">
        <div>
            <img src="https://ppyos.xijiuyou.com/202301/how-to-join-btn.png" alt="">
            <p>· 完成页面下方的任务，即可获得摸牌次数，任务每日更新</p>
            <p>· 每日的摸牌次数请及时使用，<span>每日24点次数将过期失效</span></p>
        </div>
        <div>
            <img src="https://ppyos.xijiuyou.com/202301/mjbh-btn.png" alt="">
            <p>· 摸牌1次将随机摸得1张麻将牌，单牌种类设置：<span>红中、发财、白板、东、南、西、北、一万、五万、九万、一筒、五筒、九筒、一条、五条、九条</span>，共16种牌</p>
            <p>· 每张牌可刮得现金等奖励，还能组成特殊牌型兑换更高奖励，每张牌仅可兑换一次，<span>现金奖励均以彩贝形式发放</span></p>
        </div>
        <div>
            <img src="https://ppyos.xijiuyou.com/202301/zj-btn.png" alt="">
            <p>a. 集齐16种全牌：8888元</p>
            <p>b. 东南西北：18.8元</p>
            <p>c. 单张发财：3.8元</p>
            <p>d. 单张红中：1.8元</p>
            <p>e. 3张相同牌（限万、筒、条牌，共9种）：0.18元</p>
            <p>f. 3张同组牌（限万、筒、条牌，共3种）：0.18元</p>
        </div>
        <div>
            <img src="https://ppyos.xijiuyou.com/202301/fj-btn.png" alt="">
            <p>· 本活动的奖励，以<span>等值彩贝</span>形式实时发放（1元=10万彩贝）<span class="blue" @click="blue">查看彩贝明细</span></p>
        </div>
        <div>
            <img src="https://ppyos.xijiuyou.com/202301/qt-btn.png" alt="">
            <p>· 活动期间，通过活动邀请新注册的泡泡鱼玩用户，可免费获得3次摸牌机会；每成功邀请1名新用户，将获得5次摸牌机会（<span>需要新用户参与活动并自摸1张麻将牌</span>），每天邀请上限为5人</p>
            <p>· 请勿通过虚假、作弊手段邀请好友（含刷号、虚假用户、大小号等情况），一经发现，将采取扣除奖励、封号等措施</p>
            <p>· 本活动解释权归泡泡鱼玩平台所有，如有其他疑问，请咨询泡泡鱼玩客服QQ1823267163或微信客服paopaoyuvip</p>
        </div>
    </div>
</template>
<script>
export default{
    methods:{
        blue(){
            this.$router.push({
                name:"caibeirecord"
            })
        }
    }
}

</script>
<style lang="less" scoped>
.guize{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #FF4431 0%, #FFCF7C 100%);
    padding-top: 27px;
    padding-bottom: 10px;
    div{
        width: calc(100% - 24px);
        background: #FFFFFF;
        border-radius: 10px;
        margin: 0 auto 27px;
        position: relative;
        padding: 20px 12px 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        img{
            position: absolute;
            width: 133px;
            left: calc(50% - 66px);
            top: -18px;
        }
        p{
            line-height: 25px;
            span{
                color: #FF3500;
            }
        }
    }
}
.guize .blue{
    color: blue;
    text-decoration: underline;
}
</style>